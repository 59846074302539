@mixin text-emphasis-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    @if $color == $white {
      // exception for white
      color: $white !important;
    } @else {
      color: $color !important;
    }
  }
  a#{$parent} {
    @include hover-focus {
      color: darken($color, 20%) !important;
    }
  }
}
