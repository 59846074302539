//mobile
.it-header-wrapper {
  .it-nav-wrapper {
    position: relative;
    // se ha un menu
    .it-brand-wrapper {
      @media (min-width: 635px) {
        padding-left: 0
      }
        padding-left: 35px;
    }
    .it-header-navbar-wrapper {
      transition: padding-top 0.3s ease;

      position: absolute;
      top: 50%;
      margin-top: -$header-nav-button-distance;
      nav {
        padding-left: 0;
        padding-right: 0;
        .custom-navbar-toggler {
          padding: 0;
          transform: translatey(-5px) translatex(5px);
          &:focus {
            outline: none;
            box-shadow: none;            
          }
          cursor: pointer;
          .icon-burger {
            background: $primary-a6;
            width: 47px;
            height: 35px;
            padding-top: 15px;
            padding-left: 5px;
            border-radius: 5px;
            span, 
            span:before,
            span:after {
              cursor: pointer;
              border-radius: 2px;
              height: 3px;
              width: 35px;
              background: white;
              position: absolute;
              display: block;
              content: '';
              transition: all 0.5s ease-in-out;
            }
            span:before {
              top: -10px;
            }
            span:after {
              bottom: -10px; 
            }
          }   
          &.open {
            .icon-burger {
              span {
                background: transparent !important;
              }
              span:after {
                top: 0;
                transform: rotate(45deg);
              }
              span:before {
                top: 0;
                transform: rotate(-45deg);
              }

            }
          }                 
        }
      }
    }
  }

  &.it-header-sticky {
    &.is-sticky {
      .it-nav-wrapper {
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 10; //livello +10 del it-navscroll

        .navbar .navbar-collapsable .navbar-nav li a.nav-link {
          font-size: 0.85em;
        }
      }

      @media (min-width: #{map-get($grid-breakpoints, lg)}) {
        .it-header-slim-wrapper,
        .it-header-center-wrapper {
          display: none;
        }

        .it-header-navbar-wrapper {
          position: fixed;
          width: 100vw;
          top: 0;
          left: -7px; // Cesku: perché occorre per allineare l'header fixed?

          .menu-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-end;

            .it-brand-wrapper {
              align-self: center;

              &.cloned {
                .it-brand-text {
                  display: none;
                }

                a {
                  color: $header-center-text-color;
                  &:hover {
                    text-decoration: none;
                  }
                  .icon {
                    fill: $header-center-text-color;
                    width: $header-center-icon-size / 2;
                    height: $header-center-icon-size / 2;
                    margin-right: $header-center-icon-margin;
                    flex-shrink: 0;
                  }
                }
              }
            }

            .it-search-wrapper {
              display: flex;
              align-items: center;
              align-self: center;
              margin-left: $header-center-search-distance / 3;
              font-size: $header-center-text-size;
              color: $header-center-text-color;
              a {
                &.rounded-icon {
                  width: $header-center-search-size;
                  height: $header-center-search-size;
                  border-radius: $header-center-search-radius;
                  display: block;
                  background: $header-center-text-color;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: $v-gap * 2;
                  transition: all 0.3s;
                  &:hover {
                    background: darken($header-center-text-color, 5%);
                  }
                  svg {
                    fill: $header-center-bg-color;
                    width: $header-center-search-icon-size;
                    height: $header-center-search-icon-size;
                  }
                }
              }

              &.cloned {
                a {
                  width: 35px;
                  height: 35px;

                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }

            .navbar-nav {
              flex: 1;

              // hide navbar-secondary if header is sticky
              &.navbar-secondary {
                display: none;
              }
            }
          }
        }
      }

      // Fix stack context issue
      & ~ * {
        // fix plugin z-index value (in conflict with sticky header)
        .owl-carousel {
          z-index: auto;
        }
      }
    }
  }
}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {
}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-navbar-wrapper {
    background-color: $primary-a6;
  }  
  .it-header-wrapper {
    .it-nav-wrapper {
      .it-brand-wrapper {
        padding-left: 0;
      }
      position: relative;
      .it-header-navbar-wrapper {
        position: inherit;
        // left: auto;
        top: inherit;
        margin-top: 0;
        nav {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .navbar
      .navbar-collapsable
      .menu-wrapper
      .nav-item.megamenu
      .dropdown-menu {
      left: 0;
      right: 0;
    }
  }
}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {

}
