html,
body {
  font-size: 16px;
  line-height: 1.5;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
    line-height: 1.555;
  }
}

//
// Intestazioni
//

h1,
.h1 {
  letter-spacing: -1px;
  @include media-breakpoint-up(sm) {
    font-size: 2rem;
    letter-spacing: -1.2px;
    line-height: 1.25;
  }
}

h2,
.h2 {
  line-height: 1.25;
  letter-spacing: normal;
  @include media-breakpoint-up(sm) {
    font-size: 1.9rem;
    line-height: 1.2;
  }
}

h3,
.h3 {
  line-height: 1.1428;
  letter-spacing: normal;
  @include media-breakpoint-up(sm) {
    font-size: 1.777rem;
    line-height: 1.25;
  }
}

h4,
.h4 {
  line-height: 1.1666;
  letter-spacing: normal;
  @include media-breakpoint-up(sm) {
    font-size: 1.555rem;
    line-height: 1.428;
    font-weight: 600;
  }
}

h5,
.h5 {
  font-weight: 400;
  letter-spacing: normal;
  @include media-breakpoint-up(sm) {
    font-size: 1.333rem;
  }
}

h6,
.h6 {
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
}

//
// Intestazioni in evidenza
//

.display-1 {
  font-size: 3.111rem;
  @include media-breakpoint-up(sm) {
    font-size: 3.111rem;
    line-height: 1.428;
  }
}

blockquote,
.blockquote {
  font-family: $font-family-serif;
  margin: 1.5rem 0;
}

caption {
  font-size: 0.777rem;
  line-height: 1.4285;
}

b,
strong {
  font-weight: 600;
}

small,
.small {
  font-size: 0.9375rem;
  font-weight: $font-weight-normal;
  @include media-breakpoint-up(sm) {
    font-size: 0.8888rem;
  }
}

.x-small {
  font-size: 0.875rem;
  font-weight: $font-weight-normal;
  @include media-breakpoint-up(sm) {
    font-size: 0.7777rem;
  }
}

.blockquote-footer {
  color: $blockquote-small-color;

  .bg-dark & {
    color: $blockquote-small-color-dark;
  }
}

// Font Family
.text-serif {
  font-family: $font-family-serif !important;
}
.text-sans-serif {
  font-family: $font-family-sans-serif !important;
}
.text-monospace {
  font-family: $font-family-monospace !important;
}
