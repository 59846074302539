// Card Eventi
.bo-card-event {
    &::after {
        content: none;
    }

    .card-calendar {
        display: flex;
        padding: 2em 0;
        margin: 0 2em;
        position: initial;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: initial;
        margin: 0;
        width: 7em;
    }

    .chip {
        margin: 0
    }

    .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 24px;
    }
}

// HEADER
.bo-logo-icon {
    display: none
}

.bo-logo-full--mobile {
    height: 55px;
}
.bo-logo-full--default {
    display: none;
}

@media (min-width: #{map-get($grid-breakpoints, sm)}) {
    .bo-logo-full--default {
        display: block;
    }
    .bo-logo-full--mobile {
        display: none;
    }

}

@media (min-width: #{map-get($grid-breakpoints, lg)}) {
.it-header-sticky.is-sticky {
    .bo-logo-full {
        display: none
    }

    .bo-logo-icon {
        display: inline-block;
    }
}
}

// ROW WALL
.row-wall {
	.card {
        box-shadow: none;
        outline: 1px solid $card-border-color;
		&-body {
			padding: 0.5em;
            text-align: center;
			.card-title {
				margin: 0;
				a {
					display: block;
					text-align: center;
				}
			}
		}
        &-space {
            padding-bottom: 0;
        }
        &-footer {
            border-top: 0;
        }
	}
}

// CARD
.card {

    &-body {
        display: flex;
        flex-direction: column;
    }
    &-text {
        flex-grow: 1;
    }
    .calendar-date {
        justify-content: flex-start;
    }
}


// SUBSITE
.auslbo-subsite {
    .it-header-navbar-wrapper {
        background-color: $neutral-1-a7;
    }

    .it-footer-main {
        > section:first-child {
            background-color: $neutral-1-a7;
        }
    }
}

// MAIN MENU
.it-header-navbar-wrapper {
    .menu-wrapper {
        // margin-left: -20px;
        // margin-right: -15px;
    }
}

// PLONE ABOVE CONTENT BODY
#viewlet-above-content-body {
    float: right;
    width: auto;
    max-width: 19rem;
    overflow: hidden;
    background-color: #f9f9f9;
    margin-left: 1em;
    .leadImage {
        padding: 1em 0.5em;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
        margin: 0;
        img {
            width: 18rem;
            height: auto;
        }
        figcaption {
            text-align: center;
        }
    }
    .calendar {
        margin-right: 4px;
        margin-left: 4px;// XXX Show shadow
        padding-bottom: 0.5em;
        justify-content: center;
    }
    .event-chips {
        text-align: center;
    }
    .event.summary.details {
        list-style: none;
        padding: 1rem 0 0 0;
        text-align: center;
        ul {
            list-style: none;
            padding: 0;
        }
    }
}

// BELOW CONTENT TITLE
.bo-below-title {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $primary;
    padding-bottom: 0.25rem;
    &_nav {
        a {
            font-weight: bold;
            display: flex;
            align-items: center;
        }
    }
}

body.template-listing_view #viewlet-above-content-body {
    float: none;
    max-width: none;
    margin-left: 0;
    margin-bottom: 1em;
    .leadImage {
        img {
            width: auto;
            max-width: 18rem;
            display: block;
            margin: 0 auto;
        }
    }
}

// PLONE TYPOGRAPHY AND TEXT STYLES
.documentDescription {
    @extend .lead;
    padding-bottom: 0.5rem;
}

.hiddenStructure {
    display: none;
}

// CAROUSEL
.it-carousel-wrapper.it-carousel-landscape-abstract {
    &:hover {
        .owl-nav {
            opacity: 1;
        }
    }
    .owl-nav {
        font-size: 1.5em;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        opacity: 0;
        transition: 0.5s ease-in-out opacity;
        button {
            color: $primary !important;
            cursor: pointer;
            &:focus {
                outline: 0;
                box-shadow: none;
            }
            &.disabled {
                opacity: 0.3;
                cursor: initial;
            }
        }
        .owl-prev {
            &:after {
                content: "←";
            }
        }
        .owl-next {
            &:after {
                content: "→";
            }
        }

    }
    .owl-dots {
        position: absolute;
        left: 0;
        margin-left: 0;
        text-align: center;
        right: 0;
        bottom: 0;
        height: 1.25em;
        width: calc(100% - 3em);
        margin: 0 auto;
        text-align: left;
        display: flex;
        justify-content: center;
    }
}

// GALLERY MODAL
.gallery-modal {
    .owl-item {
        justify-content: center;
        text-align: center;
        img {
            height: 50vh;
            width: auto;
            display: block;
            margin: 2em auto;
        }
    }
}

// FIGURE CAPTION
.figure {
    display: block;
    &-caption {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

// Anchor with offset
.bo-anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

// Farmacie
.bo-farmacia {
    h5 {
        margin-bottom: 0;
    }
    &_phone {
       border-top: 1px solid $gray-100;
       margin-top: 0.5em;
       padding-top: 0.5em;
    }
}

// TMP FIX for mobile menu
.navbar .navbar-collapsable .menu-wrapper {
    // left: 20px;
}


// Mega menu
.megamenu {
    p {
        margin-bottom: 0.25em;
    }
    .link-list {
        li {
            margin: 0;
        }
    }
}

// Mega menu for mobile
@media (max-width: 991px) {
    .menu-wrapper {
        max-width: 80vw;
        .dropdown-menu {
            img {
                max-width: 90%;
                height: auto;
            }
        }
    }
}

// Improved link readability in text
#theme-content #parent-fieldname-text a,
#theme-content #parent-fieldname-recap a {
    font-weight: bold;
}

// Custom badge for file downloads
.badge-file,
.link-list-wrapper ul li a.badge-file {
    display: flex !important;
    width: fit-content;
    align-items: center;
    padding: 0.5em;
    img {
        -webkit-filter: grayscale(100%);
        margin: 0 !important;
    }
    span {
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 0.5rem;
    }
    svg.icon {
        margin: 0 !important;
    }
    .filename {
        color:inherit;
        max-width: 20rem;
        display: inline-block;
    }
}

// Card listing
.entries {
    .card-body-wrapper {
        flex-direction: column;
        @media (min-width: #{map-get($grid-breakpoints, lg)}) {
            flex-direction: row;
        }
    }
    .card-img {
        .img-fluid {
            object-fit:contain
        }
    }
    .breadcrumb-container {
        display: flex;
        flex-wrap: wrap;
        background: $neutral-2-b1;
        padding: 0.5em;
    }
}

// FOOTER LINK LIST
footer {
    .footer_area_1 .link-list li {
        margin: 0.5em 0;
        a {
            line-height: normal;
        }
    }
    .footer_area_2 .link-list {
        display: flex;
        flex-wrap: wrap;
        gap: 0 2em;
        justify-content: center;
        margin-bottom: 0;
    }
}

// Autotoc
.link-list-toc {
    border: 1px solid $gray-border;
    width: calc(100% - 1em);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    padding: 0.25rem;
    background: white;
    ul {
        li {
            &:first-child {
                margin: 0;
            }
            h3 {
                padding: 0;
                color: $body-color;
            }
        }
    }
}

// Stile immagini nell'editor
#content-core .image-right {
    float: right;
    margin: 0.5em;
}
#content-core .image-left {
    float: left;
    margin: 0.5em 1em 0.5em 0;
}

// SEARCH
#search-results-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 1em;
    &-order {
        display: flex;
        align-items: flex-end;
    }
}

#search-filters {
    .collapse-header [data-toggle=collapse]{
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1em;
        margin-left: -0.5em;
    }
    &-wrapper{
        @media (min-width: 992px) {
            display: block !important;
        }
    }
}

// Plone Tables
table.listing {
    @extend .table;
    @extend .table-striped;
}

// Plone Typography
.discreet {
    @extend .small;
    color: $neutral-2-b5;
}


// Template Tabular view
body.template-tabular_view {
    .pagination-wrapper:first-of-type {
        display: none;
    }
}

// Top portlets
// The first portlet will use full with, al the rest in one row
#portal-column-two {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 2rem;
    margin-bottom: 2rem;
    .portletWrapper {
        display: inline-block;
    }
    .portletWrapper:first-child {
        width: 100%;
    }
    .portletWrapper:not(:first-child) {
        flex: 1;
    }
}

// Easyform - disaply tabs
.easyformForm.enableFormTabbing {
    .nav {
        background-color: transparent;
        .nav-link {
            &.active {
                border-left-color: $primary;
                border-top-color: $primary;
                border-right-color: $primary;
                border-bottom: none;
            }
        }
    }
    .tab-content {
        margin-top: -1px;
        fieldset {
            padding-top: 1em;
            legend {
                display: none;
            }
        }
    }
}

// Theme content display with page footer
#theme-content #portal-footer-wrapper {
    clear: both;
    padding-top: 3rem;
}
